// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import { Info } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import UserContext from "./../../Context/UserContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import UnControlledStockCounter from "./../StockCounter/UnControlledStockCounter";
import { units } from "./../../constants";
import { Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container, InputAdornment } from "@mui/material";

const validator = require("validate.js");

const AddStockDialog = (props) => {

	// consider using own context for stock items ...
	const context = useContext(UserContext);
	const editing = props.editing !== undefined ? props.editing : false;
	const hasStockItem = editing && context.stockItem !== undefined;
	const impersonate = props.impersonate === undefined ? false : props.impersonate;

	// fields
	const [errors, setErrors] = useState(null);
	const [name, setName] = useState(editing && context.stockItemName !== undefined ? context.stockItemName : "");
	const [category, setCategory] = useState(hasStockItem ? context.stockItem.category.id : "");
	const [location, setLocation] = useState("");
	const [creatingLocation, setCreatingLocation] = useState(false);
	const [customCategory, setCustomCategory] = useState("none");
	const [customLocation, setCustomLocation] = useState("");
	const [uom, setUom] = useState(hasStockItem ? context.stockItem.units_of_measure : ""); // units of measure to select from, either weight or volume
	const [uomValues, setUomValues] = useState(hasStockItem ? units[context.stockItem.units_of_measure] : []); // values the user can choose from once uom has been selected
	const [uomSelection, setUomSelection] = useState(hasStockItem ? context.stockItem.units : ""); // user has selected what they want to measure the stock in ...
	const [quantityDescription, setQuantityDescription] = useState(hasStockItem ? context.stockItem.quantity_description : "");
	const [standardQuantity, setStandardQuantity] = useState(hasStockItem ? context.stockItem.standard_quantity : "");
	const [availableQuantity, setAvailableQuantity] = useState(hasStockItem ? context.stockItem.available_quantity : "");
	const [availableUnits, setAvailableUnits] = useState(1);
	const [quantityAlert, setQuantityAlert] = useState(hasStockItem ? context.stockItem.quantity_alert : "");

	// data
	const [categories, setCategories] = useState(null);
	const [locations, setLocations] = useState(null);

	// other state
	const [selectedLocations, setSelectedLocations] = useState(hasStockItem ? context.stockItem.stocktaking_locations.map((e,i)=>{ return e.stocktaking_location}) : []);

	// effects
	// load all data at once ...
	// locations, categories ...
	useEffect(()=>{
		if(categories !== null){
			return;
		}
		const s =  async (response) => {
			let data = response.data;
			data = data.filter((x)=>{ return x.active });
			setCategories(data);
			let ls = null;
			if(!impersonate){
				ls = await context.api.getStocktakingLocationsSync(context);
			}else{
				const payload = {
					"user_id": context.impersonatedUserId,
					"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stocktaking-locations`,
      		"method": "GET"
				};
				ls = await context.api.doImpersonateSync(context, payload);
			}
			if(ls.status !== 200){
				throw new Error(`Failed to get locations, status was ${ls.status}`);
			}	
			ls = ls.data;
			ls = ls.filter((x)=>{ return x.active });
			setLocations(ls);
		};
		const e = async (error) => {
			toast.error("Error, failed to get categories and locations. Please contact support.");
		};
		if(!impersonate){
			context.api.getCategories(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-categories`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});
	const doValidation = (field) => {
		const payload = getPayload();
		const constraints = getConstraints(payload);
		const errors = validator.validate(payload, constraints);
		setErrors(null);
		if(errors[field] !== undefined){
			const copy = {};
			copy[field] = errors[field];	
			setErrors(copy);
			return;
		}
	};
	const onUomChanged = (unit) => {
		let values = units[unit];
		if(values === undefined){
			throw new Error(`Failed to find values for uom ${unit}`);
		}
		values = [{value:"", label:"Please select a unit ..."}, ...values];
		setUom(unit);
		setUomValues(values);
		setUomSelection("");
	};
	const addLocation = (id) => {
		// check for creation of new location ...
		const CREATE_NEW_LOCATION = "-2";
		// reset this by default
		// we dont want this sticking around 
		setCreatingLocation(false);
		if(id === CREATE_NEW_LOCATION){
			setCreatingLocation(true);
			setLocation(null);
			return;
		}
		// find location ...
		const l = locations.find((x)=>{ return x.id === id });	
		if(l === undefined){	
			toast.error(`Error, failed to find location for id ${id}`);	
			return;
		}
		// check for existing selection
		const copy = [...selectedLocations];
		const existing = copy.find((x)=>{ return x.id === l.id });
		if(existing !== undefined){
			toast.info(`${l.name} has already been added, please add a different location.`);
			return;
		}
		// push onto selection, update state
		copy.push(l);
		setSelectedLocations(copy);
		setLocation(null);
	};
	const removeLocation = (id) => {
		const copy = [...selectedLocations];
		const index = copy.findIndex((x)=>{ return x.id === id});	
		copy.splice(index, 1);
		setSelectedLocations(copy);
	};
	const getPayload = () => {
		return {
			name: name,
			units_of_measure: uom,
			units: uomSelection,
			standard_quantity: standardQuantity,
			available_quantity: availableQuantity,
			quantity_description: quantityDescription,
			quantity_alert: quantityAlert,
			stocktaking_location_ids: selectedLocations.map((e)=>{ return `${e.id}` }).join(","),
			stock_category_id: category, // -2 for custom category
			custom_category: customCategory,  // optional, name of custom category
			location_id: context.selectedLocation.id
		};	
	};
	const convertPayload = (payload) => {
		// force appropriate strings to be numbers
		// backend will require this otherwise validation will fail
		const copy = {...payload};
		copy.standard_quantity *= 1;
		copy.available_quantity *= 1;
		copy.quantity_alert *= 1;
		copy.stock_category_id *= 1;
		copy.location_id *= 1;
		if(copy.stock_category_id !== -2){
			copy.custom_category = "none";
		}
		return copy;
	};
	const getConstraints = (payload) => {
		let constraints = {
			name: { presence: { allowEmpty: false },  }, // required, string
			units_of_measure: { presence: { allowEmpty: false },  }, // required, string
			units: { presence: { allowEmpty: false } }, // requried, string
			standard_quantity: { presence: { allowEmpty: false }, numericality: true }, // required, number
			available_quantity: { presence: { allowEmpty: false },  numericality: true  }, // required, number
			quantity_description: { presence: { allowEmpty: false },  }, // required, string
			quantity_alert: { presence: { allowEmpty: true }, numericality: true }, // required, number
			stocktaking_location_ids: { presence: { allowEmpty: false }  }, // required, string
			stock_category_id: { presence: { allowEmpty: false }, numericality: true,  }, // required, integer
			location_id: { presence: { allowEmpty: false }, numericality: true }, // required, integer
		};
		// creating new category
		// custom category requried field
		if(payload.stock_category_id === -2){
			constraints = {...constraints, custom_category: { presence: { allowEmpty: false } } };
		}
		return constraints;
	};
	const getError = (field) => {
		return errors !== null && errors[field] !== undefined;
	}
	const getHelperText = (field, message) => {
		if(getError(field)){
			return errors[field].join(", ");
		}	
		return message;
	};
	const createCustomLocation = () => {
		const constraints = {
			name: { presence: { allowEmpty: false }, length: { maximum: 255 } }
		};
		const payload = {
			name: customLocation,
			location_id: context.selectedLocation.id
		};
		setErrors(null);
		const errors = validator.validate(payload, constraints);
		if(errors !== undefined){
			errors.custom_location = errors.name;
			delete errors.name;
			setErrors(errors);
			return;
		}
		const s = (response) => {
			const created = response.data;
			const copy = [...selectedLocations];
			const existing = [...locations];
			copy.push(created);
			existing.push(created);
			setCreatingLocation(false);
			setSelectedLocations(copy);
			setLocations(existing);
		}
		const e = (error) => {
			toast.error("Failed to create new location ...");
		};
		context.api.addStocktakingLocation(context, s, e, payload);	
	};
	const onSaveClose = (event) => {
		let payload = getPayload();
		const constraints = getConstraints(payload);
		setErrors(null);
		const errors = validator.validate(payload, constraints);
		if(errors !== undefined){
			setErrors(errors);
			return;
		}
		const e = (error) => {
			if(editing){
				toast.error("Failed to update stock item, please contact support.");
				return;
			}
			toast.error("Failed to add stock item, please contact support.");
		};
		const s = (response) => {
			if(editing){
				const item = response.data;
				toast.success("Stock item updated");
				props.onStockUpdated(item);
				props.onCloseClicked();
				return;
			}
			toast.success("Stock item added");
			props.onStockItemAdded();
			props.onCloseClicked();
		};
		payload = convertPayload(payload);
		if(!editing){
			if(!impersonate){
				context.api.addStockItem(context, s, e, payload);
				return;
			}
			const impersonated = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      	"method": "POST",
				"data": JSON.stringify(payload)
			};
			context.api.doImpersonate(context, s, e, impersonated);
			return;
		}
		if(!impersonate){
			// editing ...
			context.api.updateStockItem(context, s, e, payload, context.stockItem.id);
			return;
		}
		const impersonated = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items/${context.stockItem.id}`,
      "method": "PUT",
			"data": JSON.stringify(payload)
		};
		context.api.doImpersonate(context, s, e, impersonated);
	};
	const onSaveAdd = (event) => {
		toast.info("Saving stock item ...");	
	};
	const customCategoryField = (
		<TextField 
			error={getError("custom_category")}
			onBlur={(event)=>{doValidation("custom_category")}}
			value={customCategory} 
			fullWidth 
			onChange={(event)=>{setCustomCategory(event.target.value);}} 
			label="Custom Category" 
			helperText={getHelperText("custom_category", "Please enter a name for your category.")}/>
	);
	const customLocationField = (
		<TextField 
			error={getError("custom_location")}
			value={customLocation} 
			fullWidth 
			InputProps={{endAdornment:<InputAdornment><IconButton onClick={createCustomLocation}><AddIcon/></IconButton></InputAdornment>}}
			onChange={(event)=>{setCustomLocation(event.target.value);}} 
			label="Custom Location" 
			helperText={getHelperText("custom_location", "Please enter a name for your new location.")}/>
	);
	const onQuantitiesChanged = (values) => {
		const u = values.units;
		const a = values.absolute;
		setAvailableUnits(u);
		setAvailableQuantity(a);
	};
	let content = (
		<LoadingSpinner />
	);
	let availableQuantityWidget = (
		<Box sx={{borderRadius:"5px", border:"1px solid #CDCDCD", textAlign:"center", padding:"5px"}}>
			<Typography variant={"body1"}>
				<Info/><br/>
				Please select a unit of measure <b>and</b> a standard stock size before configuring available quantity.
			</Typography>
		</Box>
	);
	if(uomSelection !== null && uomSelection !== ""){
		availableQuantityWidget = (
			<Box sx={{borderRadius:"5px", border:"1px solid #CDCDCD", padding:"15px"}}>
			<Grid container>
			<Grid item lg={3}>
				<Typography variant={"body1"} sx={{textAlign:"center"}}>Available Quantity</Typography>
			</Grid>
			<Grid item lg={9}>
			<UnControlledStockCounter 
				standardQuantity={standardQuantity} 
				absoluteQuantity={availableQuantity} 
				unitQuantity={availableUnits} 
				units={uomSelection}
				onQuantitiesChanged={onQuantitiesChanged}
				onAbsoluteQuantityChanged={(event)=>{setAvailableQuantity(event.target.value);}} 
				onUnitQuantityChanged={(event)=>{setAvailableUnits(event.target.value);}}/>
			</Grid>
			</Grid>
			</Box>
		);
	}
	// only  show the following when locations and categories
	// have been loaded
	if(categories !== null && locations !== null){
		let categoryOptions = categories.map((e,i)=>{ return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>});
		const pleaseSelect = <MenuItem value={"-1"}>Please select an option</MenuItem>;
		const createCategory = <MenuItem value={"-2"}><span><AddIcon sx={{mr:"5px", verticalAlign:"middle"}}/>Create a new category</span></MenuItem>;
		const createLocation = <MenuItem value={"-2"}><span><AddIcon sx={{mr:"5px", verticalAlign:"middle"}}/>Create a new location</span></MenuItem>;
		categoryOptions = [ pleaseSelect, createCategory, ...categoryOptions ];
		let locationOptions = locations.map((e,i)=>{ return <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>});
		locationOptions = [ pleaseSelect, createLocation, ...locationOptions ];
		content = (
			<Box sx={{overflowY:"scroll", maxHeight:"500px", paddingRight:"25px" ,paddingBottom:"10px", paddingTop:"10px"}} id="scollable">
			<span>{`Has stock item: ${hasStockItem}`}</span>
			<Stack spacing={2}>
				<TextField 
					error={getError("name")}
					value={name} 
					fullWidth
					size={"normal"}
					onChange={(event)=>{setName(event.target.value);}} 
					onBlur={(event)=>{doValidation("name")}}
					label="Name" 
					helperText={getHelperText("name", "Please enter a name for this stock item.")}/>
				<TextField 
						select
						error={getError("stock_category_id")}
						value={category} 
						fullWidth 
						size={"normal"}
						onChange={(event)=>{setCategory(event.target.value);}} 
						onBlur={(event)=>{doValidation("stock_category_id")}}
						label="Category" 
						helperText="Please select a category.">
					{categoryOptions}
				</TextField>
				{category * 1 === -2 ? customCategoryField : null}
				<TextField 
						select
						value={location} 
						fullWidth 
						size={"normal"}
						onChange={(event)=>{addLocation(event.target.value);}} 
						label="Stocktaking Locations" 
						helperText="Stocking locations are the places within a premises where stock is kept.">
					{locationOptions}
				</TextField>
				{creatingLocation ? customLocationField : null}
				<Box sx={{backgroundColor:"white", padding:"10px", border:"1px solid #CDCDCD", borderRadius:"5px"}}>
					{selectedLocations.length === 0 ? <Typography sx={{textAlign:"center", fontSize:"20px", fontWeight:"normal"}}>No locations have been selected.</Typography> : selectedLocations.map((e,i)=>{ return <Chip color="secondary" sx={{marginRight:"15px"}} label={e.name} onDelete={(event)=>{removeLocation(e.id)}}/>})}	
				</Box>
				<TextField 
						error={getError("units_of_measure")}
						onBlur={(event)=>{doValidation("units_of_measure")}}
						select
						value={uom} 
						fullWidth 
						size={"normal"}
						onChange={(event)=>{onUomChanged(event.target.value);}} 
						label="Unit of Measure" 
						helperText={getHelperText("units_of_measure", "Is the stock item measured by weight or volume?")}>
					<MenuItem value={""}>Please select a unit of measure ...</MenuItem>
					<MenuItem value={"weight"}>Weight</MenuItem>
					<MenuItem value={"volume"}>Volume</MenuItem>
					<MenuItem value={"units"}>Units</MenuItem>
				</TextField>
				<TextField 
						error={getError("units")}
						onBlur={(event)=>{doValidation("units")}}
						select
						defaultValue={""}
						value={uomSelection} 
						fullWidth 
						size={"normal"}
						onChange={(event)=>{setUomSelection(event.target.value);}}
						helperText={getHelperText("units", "Please select an appropriate unit")}> 
					{uomValues.length === 0 ? <MenuItem value={""}>Select a unit of measure first.</MenuItem> : uomValues.map((e,i)=>{ return <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>})}
				</TextField>
				<TextField 
					error={getError("standard_quantity")}
					onBlur={(event)=>{doValidation("standard_quantity")}}
					value={standardQuantity} 
					fullWidth 
					size={"normal"}
					onChange={(event)=>{setStandardQuantity(event.target.value);}} 
					label="Standard stock item size" 
					helperText={getHelperText("standard_quantity", "Enter as a number. For example tomato sauce comes in '2' litre bottles.")} />
				<TextField 
					error={getError("quantity_description")}
					onBlur={(event)=>{doValidation("quantity_description")}}
					value={quantityDescription} 
					fullWidth 
					size={"normal"}
					onChange={(event)=>{setQuantityDescription(event.target.value);}} 
					label="Container Description" 
					helperText={getHelperText("quantity_description","The description of this stock item, for example 700ml bottle.")} />
					{availableQuantityWidget}	
				<TextField 
					error={getError("quantity_alert")}
					onBlur={(event)=>{doValidation("quantity_alert")}}
					value={quantityAlert} 
					fullWidth 
					size={"normal"}
					onChange={(event)=>{setQuantityAlert(event.target.value);}} 
					label="Quantity Alert" 
					helperText={getHelperText("quantity_alert", "You'll receive an alert once the quantity of this stock items falls below this value.")} />
				<Grid container>
					<Grid item xs={12} lg={4}>
						<Button variant="outlined" fullWidth 
							sx={{color:"black", border:"1px solid #E6E6E6", borderRadius:"18px 18px 18px 18px"}} 
							onClick={props.onCloseClicked}>
							Cancel
						</Button>
					</Grid>
					<Grid xs={12} lg={4}>
					{/* leave space in middle */}
					</Grid>
					<Grid item xs={12} lg={4}>
						<Button variant="contained" fullWidth className="pg" 
							sx={{borderRadius:"18px 18px 18px 18px"}} 
							onClick={onSaveClose}>
							Save and Close
						</Button>
					</Grid>
				</Grid>
			</Stack>
			</Box>
		);	
	}
	return (
		<InStockDialog title={editing ? "Edit Stock Item" : "Add New Stock Item"} onCloseClicked={props.onCloseClicked}>
			{content}
		</InStockDialog>
	);
};
export default React.memo(AddStockDialog);
